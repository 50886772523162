.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #2dd4bf;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link-active > i {
  color: #22fca8 !important;
}

.nav-link-active > span {
  color: #22fca8 !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: none;
}

.p-datepicker table {
  font-size: 10px;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker table td > span.p-highlight {
  background: #22fca8;
}

.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 1rem 1.25rem;
}

.p-card .p-card-content {
  padding: 0;
}

.p-menu .p-menuitem-link {
  color: #494949;
  font-weight: 500;
}

.p-submenu-header {
  font-weight: normal;
}